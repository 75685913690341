import { AxiosManager } from "../axiosManager";
import { getAccessTokenFromLocalStorage } from "../common/constants";
// 로컬 스토리지에서 토큰을 가져와서 API 요청
export function reqShip(payload: any) {
  return AxiosManager.get(`ship/${payload.id}`);
}

export function reqAllShip() {
  const accessToken = getAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 가져오기
  if (!accessToken) {
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`ship/ontact`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 토큰 추가
    },
  });
}

export function reqAllShipOrder() {
  const accessToken = getAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 가져오기
  if (!accessToken) {
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`ship/ontact/shiporder`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 토큰 추가
    },
  });
}

export function reqAllBoxingOrders() {
  const accessToken = getAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 가져오기
  if (!accessToken) {
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`ship/ontact/boxing`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 토큰 추가
    },
  });
}

export function reqAllShipmentOrders() {
  const accessToken = getAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 가져오기
  if (!accessToken) {
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`ship/ontact/shipmentorders`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 토큰 추가
    },
  });
}

export function reqAllShippay() {
  const accessToken = getAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 가져오기
  if (!accessToken) {
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`ship/ontact/shipay`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 토큰 추가
    },
  });
}
export function reqAllShipaid() {
  const accessToken = getAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 가져오기
  if (!accessToken) {
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`ship/ontact/shipaid`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 토큰 추가
    },
  });
}

export function reqShiprevenue(startDate: any) {
  return AxiosManager.get(`ship/revenue/${startDate}`);
}

export function requserShip(userId: string) {
  // 로컬 스토리지에서 "state"를 가져옴
  const state = localStorage.getItem("state");

  // "state"가 존재하면 JSON으로 파싱하여 token.accessToken을 가져옴
  const accessToken = state ? JSON.parse(state)?.token?.accessToken : null;

  if (!accessToken) {
    throw new Error("No access token found"); // 토큰이 없을 때 오류 처리
  }

  return AxiosManager.get(`ship/user/${userId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Authorization 헤더에 Bearer 토큰 추가
    },
  });
}

// 로컬 스토리지에서 토큰을 가져와서 API 요청
export function reqAShip() {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`product`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // 토큰을 Authorization 헤더에 추가
    },
  });
}

// 로컬 스토리지에서 토큰을 가져와서 API 요청
export function reqAShipstatus() {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return AxiosManager.get(`product/status`, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // 토큰을 Authorization 헤더에 추가
    },
  });
}

export function reqproduct(tracking: string) {
  return AxiosManager.get(`product/${tracking}`);
}

export function reqProductOfUser(userId: string) {
  return AxiosManager.get(`product/user/${userId}`);
}

export function reqShipSendOrder(payload: any) {
  return AxiosManager.post("ship/sendorder", payload);
}

export function reqShipUpdateOrder(payload: any) {
  return AxiosManager.put(`ship/${payload.id}/status`, payload);
}

export function reqShipUpdateOrders(payload: any) {
  return AxiosManager.put(`ship/${payload.id}/cancel`, payload);
}

export function reqShipOrderUpdate(payload: any) {
  return AxiosManager.put(`ship/${payload.id}`, payload);
}

export function reqShipProductUpdate(payload: any) {
  return AxiosManager.post("product/detail", payload);
}

export function reqlost(payload: any) {
  return AxiosManager.post("lost-shipment-requests", payload);
}

export function reqlostGet() {
  const accessToken = getAccessTokenFromLocalStorage();
  if (!accessToken) {
    console.error("Access token is missing");
    throw new Error("Access token is missing");
  }

  return AxiosManager.get("lost-shipment-requests", {
    headers: {
      Authorization: `Bearer ${accessToken}`, // 토큰을 Authorization 헤더에 추가
    },
  });
}

export function reqLostDetail(id: number) {
  return AxiosManager.get(`lost-shipment-requests/${id}`);
}

export function reqShipProductDetailUpdate(payload: any) {
  return AxiosManager.post(`ship/details`, payload);
}

export function reqUploadShipOrderImage(id: string, file: any) {
  const formData = new FormData();
  formData.append("file", file);

  return AxiosManager.post(`ship/upload-image/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function reqShipProductDetail(payload: any) {
  return AxiosManager.put(`ship/${payload.id}/details`, payload);
}

export function reqShipProductBox(payload: any) {
  return AxiosManager.put(`product/${payload.tracking}`, payload);
}

export function reqShipProductDelete(payload: any) {
  return AxiosManager.delete(`product/${payload.tracking}`, payload);
}

export function reqShipDetail(payload: any) {
  return AxiosManager.get(`ship/${payload.order_id}/details`);
}

export function reqShipCompleteForCron(payload: any) {
  return AxiosManager.post(`product/complete/cron/${payload.id}`);
}

export function reqShipFEE(payload?: any) {
  return AxiosManager.get("shipping-configurations", { params: payload });
}

export function reqShipFEEUpdate(id: any, payload: any) {
  return AxiosManager.put(`shipping-configurations/${id}`, payload);
}

export function reqShipZone(data: any) {
  return AxiosManager.post("zones", data);
}

export function reqShipDelete() {
  return AxiosManager.delete("zones");
}

export function reqShipZoneGet() {
  return AxiosManager.get("zones");
}

export function reqfilterByUserId(userId: string) {
  return AxiosManager.post(`product/filter/userId`, { userId });
}

export function reqfilterByTracking(tracking: string) {
  return AxiosManager.post(`product/filter/tracking`, { tracking });
}

export function reqfilterByDateRange(payload: {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
}) {
  return AxiosManager.post(`product/filter/date-range`, payload);
}

export function reqfilterByStatusAndUserId(userId: string, status: number[]) {
  return AxiosManager.post("product/filter/status-and-user", {
    userId,
    status,
  });
}

export function reqAShipS(page = 1, limit = 10, status?: string) {
  return AxiosManager.get(
    `product/page?page=${page}&limit=${limit}&status=${status}`
  );
}

export function reqShipZoneUpdate(id: any, data: any) {
  return AxiosManager.put(`zones/${id}`, data);
}

export function reqShipTrackingGet(payload: any) {
  return AxiosManager.get(`ship/tracking/orders/${payload.id}`);
}

export function reqShipToAlarmSend(payload: any) {
  return AxiosManager.post("ship/send/alarm", payload);
}

export function reqProductDetail(payload: any) {
  return AxiosManager.get(`ship/product-detail/${payload.id}`);
}

export function reqPutExpress(payload: any) {
  return AxiosManager.put(`ship/details/${payload.id}`, payload);
}

export function reqReactivateExpiredProduct(tracking: string) {
  return AxiosManager.post(`product/reactivate/${tracking}`);
}
