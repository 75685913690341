import React, { useEffect, useState } from "react";
import { Pagination, Spinner, Table } from "react-bootstrap";
import styled from "styled-components";
import {
  ADMIN_BADGE,
  CODE_SHOPING_ORDER_STATUS,
} from "../../../common/constants";
import AdminHeadFilter from "../../../components/AdminHeadFilter";
import AdminHeadTitle from "../../../components/AdminHeadTitle";
import FancallModal from "../../../components/modal/FancallModal";
import { reqReadAdminBadge } from "../../../requests/badge";
import {
  reqAShop,
  reqDropAllTransaction,
  reqShopUpdate,
} from "../../../requests/shop";
type OrderData = {
  orderId: string;
  userId: string;
  createdAt: string;
  PaymentMethod: number;
  preorder: boolean;
  fancall: boolean;
  status: number;
  user: {
    name: string;
    membershipId: number;
  };
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0;
`;

const AShopOrder: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<OrderData | null>(null);
  const [orders, setOrders] = useState<OrderData[]>([]);

  const handleOrderClick = (order: OrderData) => {
    setCurrentOrder(order);
    setShowModal(true);
    checkToTransaction(order.orderId);
  };

  const handleOrderClickOff = () => {
    setShowModal(false);
    dropToTransaction();
  };

  const checkToTransaction = async (orderId: string) => {
    await reqShopUpdate({
      order_id: orderId,
      status: CODE_SHOPING_ORDER_STATUS.TRANSACTION_OF_1_BETWEEN_2,
    });
  };

  const dropToTransaction = async () => {
    if (
      Number(currentOrder?.status) ===
      CODE_SHOPING_ORDER_STATUS.TRANSACTION_OF_1_BETWEEN_2
    ) {
      await reqShopUpdate({
        order_id: currentOrder?.orderId,
        status: CODE_SHOPING_ORDER_STATUS.ORDER_CREATED,
      });
    }
  };

  const refreshData = () => {
    fetchOrders();
  };

  useEffect(() => {
    fetchOrders();
    const badgePayload = {
      badgeName: ADMIN_BADGE.SHOP_REQUEST_FORM,
    };
    reqReadAdminBadge(badgePayload);
  }, []);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      // 페이지 강제 이탈로 인한 트랜잭션 drop
      await reqDropAllTransaction();

      const response = await reqAShop();
      const filteredOrders = response.data.filter(
        (order: OrderData) =>
          order.status === CODE_SHOPING_ORDER_STATUS.ORDER_CREATED
      );
      setOrders(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMembershipColor = (membershipId: number) => {
    switch (membershipId) {
      case 1:
        return "RED";
      case 2:
        return "BLUE";
      case 3:
        return "GREEN";
      default:
        return "알 수 없음";
    }
  };

  const handleReopenModal = () => {
    setShowModal(true);
  };

  //! 필터 시작
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [searchWord, setSearchWord] = useState<string>("");
  const [searchOption, setSearchOption] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const thisFilteredData = orders
      .filter((order: any) =>
        order.orderId.toUpperCase().includes(searchWord.toUpperCase())
      )
      .sort(
        (a: any, b: any) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    // const thisFilteredData = orders.filter(
    //   (order) =>
    //     order.orderId.toLowerCase().includes(searchWord.toLowerCase()) &&
    //     (selectedStatus ? order.status.toString() === selectedStatus : true)
    // );
    setFilteredData(thisFilteredData);
    setCurrentPage(1);
    setTotalPages(Math.ceil(thisFilteredData.length / itemsPerPage));
  }, [orders, searchWord, itemsPerPage, selectedStatus]);

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedOrders = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  //! 필터 끝
  // 한국 시간
  const formatDateKorean = (dateString: string) => {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 9);
    return date.toISOString().slice(0, 16).replace("T", " ");
  };
  return (
    <main id="main" className="main">
      <AdminHeadTitle
        subtitle1="구매대행"
        subtitle2="구매대행 요청서"
        title="구매대행 요청서"
      />

      {/* 옵션 필터 */}
      <AdminHeadFilter
        selectViewPage={(value: number) => handleItemsPerPageChange(value)}
        enterSearch={(value: string) => setSearchWord(value)}
        selectSearchOption={(value: string) => setSearchOption(value)}
      />

      {isLoading ? (
        <LoadingContainer>
          <Spinner animation="border" variant="primary" />
          <LoadingText>데이터를 불러오는 중입니다...</LoadingText>
        </LoadingContainer>
      ) : (
        <>
          <div className="table-responsive">
            <Table className="table-custom text-center">
              <thead className="table-light">
                <tr>
                  <th>주문번호</th>
                  <th>주문날자</th>
                  <th>스페이스코드</th>
                  <th>이름</th>
                  <th>멤버십</th>
                  <th>특이사항</th>
                </tr>
              </thead>
              <tbody>
                {displayedOrders.map((order) => (
                  <tr
                    key={order.orderId}
                    onClick={() => handleOrderClick(order)}
                    style={{ cursor: "pointer" }}
                  >
                    <th>{order.orderId}</th>
                    <td>{formatDateKorean(order.createdAt)}</td>
                    <td>{order.userId}</td>
                    <td>{order.user.name}</td>
                    <td>
                      <span
                        style={{
                          color: getMembershipColor(order.user.membershipId),
                        }}
                      >
                        {getMembershipColor(order.user.membershipId)}
                      </span>
                    </td>
                    <td>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={order.preorder}
                          readOnly
                        />
                        <label className="form-check-label">Preorder</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={order.fancall}
                          readOnly
                        />
                        <label className="form-check-label">Fancall</label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* 페이지 바 */}
          <CenteredPagination style={{ marginTop: "1rem" }}>
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </CenteredPagination>
        </>
      )}

      {currentOrder && (
        <FancallModal
          show={showModal}
          onHide={() => handleOrderClickOff()}
          onReopen={handleReopenModal}
          orderId={currentOrder.orderId}
          refreshData={refreshData}
        />
      )}
    </main>
  );
};

const CenteredPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export default AShopOrder;
